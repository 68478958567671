<template>
    <v-card flat outlined rounded="lg" :height="height" :max-height="maxHeight" :width="width" class="pa-4">
        <v-card-title class="text-h6">
            <slot name="header" />
        </v-card-title>
        <slot />
    </v-card>
</template>

<script>
export default {
    props: {
        height: String,
        maxHeight: String,
        width: String,
    },
    setup() {
        return {};
    },
};
</script>
