<template>
    <v-container fill-height fluid>
        <v-row>
            <v-col align="center">
                <SignUpForm />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SignUpForm from "@/components/SignUpForm.vue";
import { useConstants } from "@/const/const.js";
const constants = useConstants();

export default {
    name: "RegisterView",
    props: {},
    components: {
        SignUpForm,
    },
    setup() {
        return {};
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: "%s | Register",
            link: [
                {rel: "canonical", href: `https://${constants.web_url}/register`}
            ]
        };
    },
};
</script>
