<template>
    <MyCard :height="380" width="500">
        <template v-slot:header>
            Create your account
        </template>
        <div v-if="submited">
            <p>
                Please click the link in the email we've sent to {{ email }}.
            </p>
            <p>
                If you haven't received the email within 10 minutes then
                <a :href="`mailto:support@${constants.domain}`">contact us</a> and we can help out.
            </p>
        </div>
        <v-form @keyup.native.enter="submit" v-else>
            <!-- hint="An activation email will be sent to this emaill address and it will be used for password reset." -->
            <v-text-field
                label="Email address"
                v-model="email"
                persistent-hint
                outlined
                @click="resetError"
                :error-messages="emailErrorMessages"
            />
            <v-text-field
                label="Password"
                v-model="password"
                :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (passwordVisible = !passwordVisible)"
                :type="passwordVisible ? 'text' : 'password'"
                outlined
            />
            <v-btn color="primary" rounded @click="submit" class="my-4">Create account</v-btn>
            <div class="my-4">Have an account? <a :href="`https://${constants.app_url}`">Sign in</a></div>
        </v-form>
    </MyCard>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { useConstants } from "@/const/const.js";
import { useRoute } from "vue2-helpers/vue-router";
import MyCard from "@/components/MyCard.vue";

export default {
    props: {},
    components: {
        MyCard,
    },
    setup() {
        const constants = useConstants();
        const route = useRoute();

        const email = ref("");
        const password = ref("");

        const emailErrorMessages = ref("");
        const submited = ref(false);

        const firstName = ref("");
        const lastName = ref("");
        const companyName = ref("");
        const city = ref("");
        const country = ref("");

        const passwordVisible = ref(false);
        const countries = ref([]);
        function resetError() {
            emailErrorMessages.value = "";
        }

        function submit() {
            var postData = { email: email.value, password: password.value };
            if ("plan" in route.query) {
                postData.plan = route.query.plan;
            }
            axios
                .post(`/api/v1/register`, postData)
                .then(() => {
                    submited.value = true;
                })
                .catch((error) => {
                    if (error.response.data.error) {
                        emailErrorMessages.value = error.response.data.message;
                    }
                });
        }

        return {
            email,
            emailErrorMessages,
            password,
            submited,
            firstName,
            lastName,
            companyName,
            city,
            country,
            passwordVisible,
            countries,
            resetError,
            submit,
            constants,
        };
    },
};
</script>
